import { createSlice } from "@reduxjs/toolkit";

const BlockedNumbersSlice = createSlice({
  name: "blockedNumbers",
  initialState: {
    blockedNumbers: [],

  },
  reducers: {
    setBlockedNumbers: (state, action) => {
      state.blockedNumbers = action.payload;
    },


  },
});

export const { setBlockedNumbers } = BlockedNumbersSlice.actions;

export default BlockedNumbersSlice.reducer;
